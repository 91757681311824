import { Injectable } from '@angular/core';
import { I18nService } from '@app/core/i18n.service';
import { map, Observable } from 'rxjs';

import { FileContainerBase } from '../components/files/models/FileContainer';
import { Track, TrackVariation } from '../models/classes/Track';
import { EntityWithFavorites } from './entity-with-favorites.service';
import { Response } from './local/Response';
import { SpotifySearch } from '../models/classes/SpotifySearch';
import { HttpService } from '@app/core/http/http.service';

@Injectable()
export class TracksService extends EntityWithFavorites<Track, Track, Track> {

    /// -----------------------------------------------------------------------------------------------------
    // @ CONSTRUCTOR
    // -----------------------------------------------------------------------------------------------------

    constructor(protected httpService: HttpService, protected i18nService: I18nService) {
        super('tracks', 'track', httpService, i18nService);
    }

    /// -----------------------------------------------------------------------------------------------------
    // @ PUBLIC METHODS
    // -----------------------------------------------------------------------------------------------------

    /**
     * Uploads the given file to a dedicated track specified through the given track id.
     * 
     * @param trackId the track id
     * @param fileContainer the file container holding the audio file to upload
     * @param asOriginal a boolean indicating whether the uploaded file should be treated as the original
     * @returns Observable<TrackVariation>
     */
    uploadTrackVariationByTrackId(trackId: string, fileContainer: FileContainerBase, asOriginal: boolean = false): Observable<TrackVariation> {

        // Formdata
        const formData = new FormData();

        formData.append('file', (fileContainer.original as any).file, fileContainer.original.name);

        // Query params
        let query: string = '';

        if (asOriginal === true) {
            query = '?isOriginal=true';
        }

        return this.httpService.post<Response<TrackVariation>>(`tracks/${trackId}/track-variations${query}`, formData
        ).pipe(
            map((result: Response<TrackVariation>) => {
                return result.data;
            })
        );
    }

    uploadMp3ForSimilaritySearch(fileContainer: FileContainerBase): Observable<any> {
        // Formdata
        const formData = new FormData();

        formData.append('file', (fileContainer.original as any).file, fileContainer.original.name);

        return this.httpService.post<Response<any>>(`tracks/similarity-search-upload`, formData
        ).pipe(
            map((result: Response<any>) => {
                return result.data;
            })
        );
    }

    getTracksBySpotifySearch(search: string, take: number = 10, skip: number = 0): Observable<SpotifySearch> {
        return this.httpService.get<Response<SpotifySearch>>(`tracks/spotify?search=${search}&skip=${skip}&take=${take}`)
            .pipe(
                map((result: Response<SpotifySearch>) => {
                    return result.data;
                })
            );
    }

}