import { Injectable } from '@angular/core';
import { AuthenticationService } from '@app/core/authentication/authentication.service';
import { BaseService } from '@app/shared/base/services';
import { __ } from '@app/shared/functions/object.functions';
import { Artist } from '@app/shared/models/classes/Artist';
import { Playlist } from '@app/shared/models/classes/Playlist';
import { Track } from '@app/shared/models/classes/Track';
import { FavoritesType } from '@app/shared/models/enums/FavoritesType.enum';
import { Observable, Subject, catchError, of, tap } from 'rxjs';
import { UsersService } from '../users.service';
import { Interaction } from '@app/shared/models/classes/Interaction';
import { ArtistService } from '../artist.service';
import { TracksService } from '../tracks.service';
import { PlaylistService } from '../playlist.service';

const favoritesKey = '34asdaaisadisdweqwe54dfisa3i8';

const favoriteTracksKey = '34asdaaisadisdweqwe54dfisa3i7';

const favoriteArtistsKey = '34asdaaisadisdweqwe54dfisa3i6';

const favoritePlaylistsKey = '34asdaaisadisdweqwe54dfisa3i5';

@Injectable()
export class FavoritesService extends BaseService {

    // -----------------------------------------------------------------------------------------------------
    // @ PRIVATE INSTANCE VARIABLES
    // -----------------------------------------------------------------------------------------------------

    private _favoriteTracks: Interaction[] = [];

    private _favoriteTracks$: Subject<Interaction[]> = new Subject<Interaction[]>();

    // tslint:disable-next-line:member-ordering
    favoriteTracks$: Observable<Interaction[]> = this._favoriteTracks$.asObservable();

    private _favoriteArtists: Interaction[] = [];

    private _favoriteArtists$: Subject<Interaction[]> = new Subject<Interaction[]>();

    // tslint:disable-next-line:member-ordering
    favoriteArtists$: Observable<Interaction[]> = this._favoriteArtists$.asObservable();

    private _favoritePlaylists: Interaction[] = [];

    private _favoritePlaylists$: Subject<Interaction[]> = new Subject<Interaction[]>();

    // tslint:disable-next-line:member-ordering
    favoritePlaylists$: Observable<Interaction[]> = this._favoritePlaylists$.asObservable();

    // -----------------------------------------------------------------------------------------------------
    // @ CONSTRUCTOR
    // -----------------------------------------------------------------------------------------------------

    constructor(
        private authenticationService: AuthenticationService,
        private usersService: UsersService,
        private artistsService: ArtistService,
        private tracksService: TracksService,
        private playlistService: PlaylistService
    ) {
        super();

        this.getFavorites();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC METHODS
    // -----------------------------------------------------------------------------------------------------

    toggleFavoriteByType(entity: Track | Artist | Playlist, favoritesType: string): Observable<any> {

        if (__.IsNullOrUndefined(entity)) {
            return;
        }

        let interaction: any;

        switch (favoritesType) {
            case FavoritesType.Track:
                interaction = { entityId: entity.id, entityType: 'Track', type: 'Favourite' };

                if (!this.isFavorite(entity.id, this._favoriteTracks)) {
                    this.toggleFavorite(entity, interaction, this._favoriteTracks, this._favoriteTracks$);

                    return this.tracksService
                        .createFavorites(entity.id)
                        .pipe(
                            catchError((err) => {
                                this.toggleFavorite(entity, interaction, this._favoriteTracks, this._favoriteTracks$);
                                return of(null);
                            })
                        );
                } else {
                    this.toggleFavorite(entity, interaction, this._favoriteTracks, this._favoriteTracks$);

                    return this.tracksService
                        .deleteFavorites(entity.id)
                        .pipe(
                            catchError((err) => {
                                this.toggleFavorite(entity, interaction, this._favoriteTracks, this._favoriteTracks$);
                                return of(null);
                            })
                        );
                }
            case FavoritesType.Artist:
                interaction = { entityId: entity.id, entityType: 'Artist', type: 'Favourite' };

                if (!this.isFavorite(entity.id, this._favoriteArtists)) {
                    this.toggleFavorite(entity, interaction, this._favoriteArtists, this._favoriteArtists$);

                    return this.artistsService
                        .createFavorites(entity.id)
                        .pipe(
                            tap(() => {
                            }),
                            catchError((err) => {
                                this.toggleFavorite(entity, interaction, this._favoriteArtists, this._favoriteArtists$);
                                return of(null);
                            })
                        );
                } else {
                    this.toggleFavorite(entity, interaction, this._favoriteArtists, this._favoriteArtists$);

                    return this.artistsService
                        .deleteFavorites(entity.id)
                        .pipe(
                            tap(() => {
                            }),
                            catchError((err) => {
                                this.toggleFavorite(entity, interaction, this._favoriteTracks, this._favoriteTracks$);
                                return of(null);
                            })
                        );
                }
            case FavoritesType.Playlist:
                interaction = { entityId: entity.id, entityType: 'Playlist', type: 'Favourite' };

                if (!this.isFavorite(entity.id, this._favoritePlaylists)) {
                    this.toggleFavorite(entity, interaction, this._favoritePlaylists, this._favoritePlaylists$);
                    return this.playlistService
                        .createFavorites(entity.id)
                        .pipe(
                            catchError((err) => {
                                this.toggleFavorite(entity, interaction, this._favoritePlaylists, this._favoritePlaylists$);
                                return of(null);
                            })
                        );
                } else {
                    this.toggleFavorite(entity, interaction, this._favoritePlaylists, this._favoritePlaylists$);

                    return this.playlistService
                        .deleteFavorites(entity.id)
                        .pipe(
                            catchError((err) => {
                                this.toggleFavorite(entity, interaction, this._favoritePlaylists, this._favoritePlaylists$);
                                return of(null);
                            })
                        );
                }
        }
    }

    isFavoriteByType(entityId: string, favoritesType: string): boolean {
        switch (favoritesType) {
            case FavoritesType.Track:
                return this.isFavorite(entityId, this._favoriteTracks);
            case FavoritesType.Artist:
                return this.isFavorite(entityId, this._favoriteArtists);
            case FavoritesType.Playlist:
                return this.isFavorite(entityId, this._favoritePlaylists);
        }
    }

    clearFavorites(): void {
        this._favoriteTracks = [];
        this._favoriteArtists = [];
        this._favoritePlaylists = [];
    }

    getFavorites() {
        if (this.authenticationService.isAuthenticated()) {
            this.usersService.getAllFavorites(this.authenticationService.user.id).subscribe({
                next: (interactions: Interaction[]) => {
                    this._favoriteTracks = interactions.filter(q => q.entityType == 'Track');
                    this._favoriteArtists = interactions.filter(q => q.entityType == 'Artist');
                    this._favoritePlaylists = interactions.filter(q => q.entityType == 'Playlist');
                }
            })
        }
    }

    /**
     * Gets the user favorite track variations.
     * @return The user favorite track variations if the user is not authenticated.
     */
    get favoriteTracks(): Interaction[] {
        return this._favoriteTracks;
    }

    /**
     * Gets the user favorite artists.
     * @return The user favorite track variations if the user is not authenticated.
     */
    get favoriteArtists(): Interaction[] {
        return this._favoriteArtists;
    }

    /**
     * Gets the user favorite playlists.
     * @return The user favorite track variations if the user is not authenticated.
     */
    get favoritePlaylists(): Interaction[] {
        return this._favoritePlaylists;
    }

    /// -----------------------------------------------------------------------------------------------------
    // @ PRIVATE METHODS
    // -----------------------------------------------------------------------------------------------------

    private toggleFavorite(
        entity: any,
        interaction: any,
        favorites: Interaction[],
        favorites$: Subject<any[]>
    ): void {
        const index = favorites.findIndex((q: Interaction) => q.entityId === entity.id);

        if (index === -1) {
            favorites.push(interaction);
        } else {
            favorites.splice(index, 1);
        }
    }

    private isFavorite(entityId: string, favorites: Interaction[]): boolean {
        return favorites.some(q => q.entityId === entityId);
    }
}