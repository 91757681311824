import { Injectable } from '@angular/core';
import { HttpService } from '@app/core/http/http.service';
import { I18nService } from '@app/core/i18n.service';
import { map, Observable } from 'rxjs';

import { Playlist, PlaylistCreateOptions, PlaylistUpdateOptions } from '../models/classes/Playlist';
import { EntityWithFavorites, PlaylistTracksUpdateOptions } from './entity-with-favorites.service';
import { Response } from './local/Response';

@Injectable()
export class PlaylistService extends EntityWithFavorites<Playlist, PlaylistCreateOptions, PlaylistUpdateOptions> {

    constructor(
        protected httpService: HttpService,
        protected i18nService: I18nService,
    ) {
        super('playlists', 'playlist', httpService, i18nService);
    }

    updateTracksForPlaylist(id: string, tracks: PlaylistTracksUpdateOptions): Observable<any> {
        return this.httpService.patch<Response<any>>(`playlists/${id}/tracks`, tracks).pipe(
            map((response: Response<any>) => {
                return response.data;
            })
        );
    }

    getPlaylistsTags(): Observable<string[]> {
        return this.httpService.get(`playlists/tags`).pipe(
            map((response: Response<string[]>) => {
                return response.data;
            })
        );
    }
}